<template>
  <div class="Userhl_favideo_s" style="margin-top:4rem;">
    <div class="fxiangmu_top">
      <p class="videohejititle">
        上传视频-提升你的 <span>{{ videotype_text }}</span> 水平
      </p>
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">课程名称</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="video_name"
              placeholder="请输入课程名称"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">课程封面</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img :src="fmimg" alt="" />
            <el-button plain icon="el-icon-picture">
              上传封面
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">上传视频</div>
          <div class="fxiangmu_toplir">
            <div v-if="Uploadvideotype">
              <Uploadvideo @uploadvideo="Uploadvideo_url" />
            </div>
            <div v-if="Uploadvideotype_t">
              <el-button disabled>上传完成！</el-button>
              <el-button plain @click="Uploadvideo_again"
                >重新上传视频</el-button
              >
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">添加标签</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="video_bq"
              placeholder="请输入课程标签"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">试看</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-switch
                v-model="shikan"
                active-color="#13ce66"
                inactive-color="#eee"
              >
              </el-switch>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">课程简介</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入课程简介"
              v-model="video_jj"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">详细介绍</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入详细介绍"
              v-model="video_all"
            ></el-input>
          </div>
        </li>
      </ul>
      <el-button class="xiangmusub_btn" type="primary" plain @click="subvideo"
        >发布视频</el-button
      >
    </div>
  </div>
</template>
<script>
import Uploadvideo from "../Uploadvideo.vue";
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: { Uploadvideo, Uploadimg },
  data() {
    return {
      imgalls: {
        name: "",
        width:276,
        height: 190,
      },
      Uploadvideotype: true, //上传视频 按钮
      Uploadvideotype_t: false, //上传视频完成
      shikan: false, //试看
      videotype_text: "设计", //上传视频 提升你的 **设计 水平

      /////////////    上传视频       ////////////////////
      hejiid: "", //合集id
      Uploadvideourl: "", //视频地址
      video_name: "", //视频名称
      video_bq: "", //视频标签
      shikannum: 1, //试看
      video_jj: "", //视频简介
      video_all: "", //视频详细介绍
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //封面图   公共
      /////////////     上传视频完成    ////////////////////
      userinfo:{},
    };
  },
  methods: {
    uploadimgurl(rul) {
      this.fmimg = rul;
    },
    Uploadvideo_url(url) {
      this.Uploadvideourl = url;
    },
    Uploadvideo_again() {
      this.Uploadvideourl = "";
    },
    subvideo() {
      var that = this;
      this.$axios({
headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url:"/api/getVideoAdd",
        method: "post",
        data: {
          p_id: that.hejiid,
          title: that.video_name,
          titlepic: that.fmimg,
          videoid: that.Uploadvideourl,
          smalltext: that.video_jj,
          newstext: that.video_all,
          label: that.video_bq,
          charge: that.shikannum,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.$message(res.data.messages);
          that.$router.push({ path: "/Userhl_favideo" });
        }
      });
    },
  },
  watch: {
    Uploadvideourl: function () {
      if (this.Uploadvideourl == "") {
        this.Uploadvideotype = true;
        this.Uploadvideotype_t = false;
      } else {
        this.Uploadvideotype = false;
        this.Uploadvideotype_t = true;
      }
    },
    shikan: function () {
      if (this.shikan) {
        this.shikannum = "0";
      } else {
        this.shikannum = "1";
      }
    },
  },
  created() {
      var that=this;
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      that.hejiid = that.$route.query.hejiid;
      var  typeid= that.$route.query.typeid;
      if (typeid == "22") {
        this.videotype_text = "环境设计";
      } else if (typeid == "23") {
        this.videotype_text = "产品设计";
      } else if (typeid == "24") {
        this.videotype_text = "视觉传达设计";
      } else {
        ///
      }
  },
};
</script>
<style>
@import "../../assets/css/Sjfabu.css";
</style>
